import React from "react";
import { useTranslation } from "react-i18next";

export default function CopyRight() {
  const { t } = useTranslation();

  return (
    <div className="container footer-bottom clearfix">
      <div className="copyright">
        &copy; Copyright{" "}
        <strong>
          <span>SOPROMI. </span>
        </strong>
        {t("home:allRightsReserved")}
      </div>
      <div className="credits">
        {/* <!-- All the links in the footer should remain intact. --> */}
        {/* <!-- You can delete the links only if you purchased the pro version. --> */}
        {/* <!-- Licensing information: https://bootstrapmade.com/license/ --> */}
        {/* <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/arsha-free-bootstrap-html-template-corporate/ --> */}
        {t("designedBy")} <a href="https://bootstrapmade.com/">Kaybi Inc</a>
      </div>
    </div>
  );
}
