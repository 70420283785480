import React from "react";
import { useTranslation } from "react-i18next";

export default function FooterTop() {
  const { t } = useTranslation();

  return (
    <div className="footer-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 footer-contact">
            <h3>SOPROMI</h3>
            <p>
              Cité chemin de Fer, immeuble Boké, <br />
              4 ème étage, C/ Kaloum, Conakry,
              <br />
              Guinée <br />
              <br />
              <strong>{t("home:ourPhone")}</strong> +224 621 00 00 00
              <br />
              <strong>{t("home:ourEmail")}</strong> contact@sopromi.com
              <br />
            </p>
          </div>

          {/* <div className="col-lg-3 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li>
                <i className="bx bx-chevron-right"></i> <a href="/#">Home</a>
              </li>
              <li>
                <i className="bx bx-chevron-right"></i>{" "}
                <a href="/#">About us</a>
              </li>
              <li>
                <i className="bx bx-chevron-right"></i>{" "}
                <a href="/#">Services</a>
              </li>
              <li>
                <i className="bx bx-chevron-right"></i>{" "}
                <a href="/#">Terms of service</a>
              </li>
              <li>
                <i className="bx bx-chevron-right"></i>{" "}
                <a href="/#">Privacy policy</a>
              </li>
            </ul>
          </div> */}

          <div className="col-lg-4 col-md-6 footer-links">
            <h4>{t("home:activities")}</h4>
            <ul>
              <li>
                <i className="bx bx-chevron-right"></i>{" "}
                <a href="/" target="_blank" onClick={(e) => e.preventDefault()}>
                  {t("home:serviceOneTitle")}
                </a>
              </li>
              <li>
                <i className="bx bx-chevron-right"></i>{" "}
                <a href="/" target="_blank" onClick={(e) => e.preventDefault()}>
                  {t("home:serviceTwoTitle")}
                </a>
              </li>
              <li>
                <i className="bx bx-chevron-right"></i>{" "}
                <a href="/" target="_blank" onClick={(e) => e.preventDefault()}>
                  {t("home:serviceThreeTitle")}
                </a>
              </li>
              <li>
                <i className="bx bx-chevron-right"></i>{" "}
                <a href="/" target="_blank" onClick={(e) => e.preventDefault()}>
                  {t("home:serviceFourTitle")}
                </a>
              </li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-6 footer-links">
            <h4>{t("home:ourSocialNetWork")}</h4>
            <p>{t("home:socialNetworkIntro")}</p>
            <div className="social-links mt-3">
              <a
                href="/"
                target="_blank"
                onClick={(e) => e.preventDefault()}
                className="twitter"
              >
                <i className="bx bxl-twitter"></i>
              </a>
              <a
                href="/"
                target="_blank"
                onClick={(e) => e.preventDefault()}
                className="facebook"
              >
                <i className="bx bxl-facebook"></i>
              </a>
              <a
                href="/"
                target="_blank"
                onClick={(e) => e.preventDefault()}
                className="instagram"
              >
                <i className="bx bxl-instagram"></i>
              </a>
              {/* <a href="/#" className="google-plus">
                <i className="bx bxl-skype"></i>
              </a> */}
              <a
                href="/"
                target="_blank"
                onClick={(e) => e.preventDefault()}
                className="linkedin"
              >
                <i className="bx bxl-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
