import React from "react";
import { useTranslation } from "react-i18next";

import Header from "../components/sections/Header";
import Main from "../components/sections/Main";
import Banniere from "../components/sections/Banniere";

import Footer from "../components/sections/Footer";

export default function Home() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="App">
      {/* Header */}
      <Header />
      {/* End Header */}

      <Banniere />

      {/* <!--  Main --> */}

      <Main />
      {/* <!-- End #main --> */}

      {/* <!-- ======= Footer ======= --> */}
      <Footer />
      {/* <!-- End Footer --> */}

      <a
        className="switch-language"
        href="#language"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          changeLanguage(i18n.language.includes("fr") ? "en-En" : "fr-Fr");
          return false;
        }}
      >
        {i18n.language.includes("fr") ? t("home:english") : t("home:french")}
      </a>

      <a href="/#" className="back-to-top">
        <i className="ri-arrow-up-line"></i>
      </a>
      <div id="preloader"></div>

      {/* <!-- Vendor JS Files --> */}
      <script src="%PUBLIC_URL%/assets/vendor/jquery/jquery.min.js"></script>
      <script src="%PUBLIC_URL%/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
      <script src="%PUBLIC_URL%/assets/vendor/jquery.easing/jquery.easing.min.js"></script>
      <script src="%PUBLIC_URL%/assets/vendor/php-email-form/validate.js"></script>
      <script src="%PUBLIC_URL%/assets/vendor/waypoints/jquery.waypoints.min.js"></script>
      <script src="%PUBLIC_URL%/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
      <script src="%PUBLIC_URL%/assets/vendor/venobox/venobox.min.js"></script>
      <script src="%PUBLIC_URL%/assets/vendor/owl.carousel/owl.carousel.min.js"></script>
      <script src="%PUBLIC_URL%/assets/vendor/aos/aos.js"></script>

      {/* <!-- Template Main JS File --> */}
      <script src="%PUBLIC_URL%/assets/js/main.js"></script>
    </div>
  );
}
