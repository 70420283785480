export default {
  lang: "English",
  title: "SOPROMI",
  home: "Home",
  about: "About",
  services: "Services",
  portofilio: "Portofilio",
  team: "Team",
  contact: "Contact Us",
  introduction: "Better Partner For Your Project",
  introduction2: "We are a team of talented and experiemnted engineers",
  aboutUs: "About US",
  about_1: `To write ...`,
  about_2: "To write ...",
  about_3: "To write ...",
  about_4: "To write ...",
  activities: "Branch Of Activities",
  serviceOneTitle: "To write ...",
  serviceOneContent: "To write ...",
  serviceTwoTitle: "To write ...",
  serviceTwoContent: "To write ...",
  serviceThreeTitle: "To write ...",
  serviceThreeContent: "To write ...",
  serviceFourTitle: "To write ...",
  serviceFourContent: "To write ...",
  serviceFiveTitle: "To write ...",
  serviceFiveContent: "To write ...",
  serviceSixTitle: "To write",
  serviceSixContent: "To write",
  career: "Career",
  careerIntro: "To write ...",
  availablePositions: "Available Positions",
  projectEngineer: "Project Engineer",
  spontaniousCandidate: "Spontanious Candidate",
  equipments: "Equipments",
  equipmentsIntro: "To write ...",
  heavyEquipments: "Heavy Equipment",
  pelleMecanique: "To write ...",
  marteauHydraulique: "To write",
  buldozer: "To write ...",
  chargeurSurRoue: "To write ...",
  niveleuse: "To write ...",
  rouleauCompacteur: "To write ...",
  betonniere: "To write ...",
  camionBenne: "To write ...",
  camionSemiRemorque: "To write ...",
  grue: "To write ...",
  camionPlateau: "To write ...",
  porteChar: "To write ...",
  citerneAEau: "To write ...",
  citerneACarburant: "To write ...",
  constructionMaterial: "Construction Materials",
  echafaudages: "To write ...",
  etaisMetaliques: "To write ...",
  coffragesMetalliques: "To write ...",
  betonniereEtMalaxeur: "To write ...",
  aiguilleVibrante: "To write ...",
  priseDeBetonHydrolique: "To write ...",
  machineAProjeterAvecMalaxeurFixe: "To write ...",
  ventilateurSecheur: "To write ...",
  plaqueVibrante: "To write ...",
  pilonneuse: "To write ...",
  marteauPneumatique: "To write ...",
  briseRocheHydraulique: "To write ...",
  marteauPerforateur: "To write ...",
  decoupeuse: "To write ...",
  decoupeuseDeFerABeton: "To write ...",
  boulonneuse: "To write ...",
  pompeAEau: "To write ...",
  materielDeLavage: "To write ...",
  posteASouder: "To write ...",
  systemeEclairage: "To write ...",
  contactIntro: "To write",
  thankYouForTheInterest: `Thank you for your interest in SOPROMI`,
  requireFieldIndication: "(*) Indicates that a field is required",
  address: "Address:",
  ourEmail: "Email:",
  ourPhoneNumber: "Phone Number",
  ourPhone: "Phone",
  name: "Your Name",
  email: "Your Email",
  phone: "Your Phone Number",
  subject: "Subject",
  message: "Your Message",
  sendMessage: "Send Message",
  loading: "Loading",
  iWantToRent: "Add equipments or material to rent",
  selectEquipment: "Select the equipment you want to rent",
  messageSent: "Your Message has been sent. Thank you!",
  ourSocialNetWork: "Our Social Network",
  socialNetworkIntro: "You can join us on these social networks",
  allRightsReserved: "All Rights Reserved",
  designedBy: "Designed By",
  done: "Done",
  emailError: "Please enter a valid email",
  nameError: "Please enter a valid name",
  phoneError: "Please enter a valid phone number",
  addedEquipment: "equipment to rent",
  sending: "Sending ...",
  notFound: "Page Not Found",
  goHome: "Go back to Home page",
  french: "French",
  english: "English",
};
