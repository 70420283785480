import React from "react";

// import Clients from "./Clients";
import About from "./About";
import WhyUs from "./WhyUs";
import Skills from "./Skills";
// import Services from "./Services";
import Equipements from "./Equipements";
// import CallToAction from "./CallToAction";
// import Portofilio from "./Portofilio";
// import Team from "./Team";
// import Pricing from "./Pricing";
// import FAQ from "./FAQ";
import Contact from "./Contact";

export default function Main() {
  return (
    <main id="main">
      {/* <Clients /> */}
      <About />
      <WhyUs />
      <Skills />
      <Equipements />
      {/* <Services /> */}
      {/* <CallToAction /> */}
      {/* <Portofilio /> */}
      {/* <Team /> */}
      {/* <Pricing /> */}
      {/* <FAQ /> */}
      <Contact />
    </main>
  );
}
