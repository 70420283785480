import React from "react";
import { useTranslation } from "react-i18next";

export default function Equipments() {
  const { t } = useTranslation();

  return (
    // {/* <!-- ======= Skills Section ======= --> */}
    <section id="equipments" className="services section-bg">
      <div className="container" data-aos="fade-up">
        <div className="content">
          <div className="section-title">
            <h2>{t("home:equipments")}</h2>
            <p className="font-italic">{t("home:equipmentsIntro")}</p>
          </div>
        </div>
        <div className="row">
          <div
            className="col-lg-6 pt-4 pt-lg-0 content"
            data-aos="fade-left"
            data-aos-delay="100"
          >
            <div className="row content">
              <div className="col-lg-12">
                <h3>{t("home:heavyEquipments")}</h3>
                <ul>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:pelleMecanique")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:marteauHydraulique")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:buldozer")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:chargeurSurRoue")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:niveleuse")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:rouleauCompacteur")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:betonniere")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:camionBenne")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:camionSemiRemorque")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:grue")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:camionPlateau")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:porteChar")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:citerneAEau")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:citerneACarburant")}</span>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="skills-content">
              <div className="progress">
                <span className="skill">
                  HTML <i className="val">100%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">
                  CSS <i className="val">90%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="90"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">
                  JavaScript <i className="val">75%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">
                  Photoshop <i className="val">55%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="55"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div> */}
          </div>
          <div
            className="col-lg-6 pt-4 pt-lg-0 content"
            data-aos="fade-left"
            data-aos-delay="100"
          >
            <div className="row content">
              <div className="col-lg-12">
                <h3>{t("home:constructionMaterial")}</h3>
                <ul>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:echafaudages")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:etaisMetaliques")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:coffragesMetalliques")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:betonniereEtMalaxeur")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:aiguilleVibrante")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:priseDeBetonHydrolique")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:machineAProjeterAvecMalaxeurFixe")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:ventilateurSecheur")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:plaqueVibrante")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:pilonneuse")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:marteauPneumatique")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:briseRocheHydraulique")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:marteauPerforateur")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:decoupeuse")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:decoupeuseDeFerABeton")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:boulonneuse")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:pompeAEau")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:materielDeLavage")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:posteASouder")}</span>
                  </li>
                  <li>
                    <i className="ri-check-double-line"></i>
                    <span>{t("home:systemeEclairage")}</span>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="skills-content">
              <div className="progress">
                <span className="skill">
                  HTML <i className="val">100%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">
                  CSS <i className="val">90%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="90"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">
                  JavaScript <i className="val">75%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <div className="progress">
                <span className="skill">
                  Photoshop <i className="val">55%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow="55"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div> */}
          </div>
          {/* <div
            className="col-lg-6 d-flex align-items-center"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            <img src="assets/img/skills.png" className="img-fluid" alt="" />
          </div> */}
        </div>
      </div>
    </section>
    // {/* <!-- End Skills Section --> */}
  );
}
