import React from "react";
import { useTranslation } from "react-i18next";

export default function About() {
  const { t } = useTranslation();

  return (
    // {/* <!-- ======= About Us Section ======= --> */}
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>{t("home:aboutUs")}</h2>
        </div>

        <div className="row content">
          <div className="col-lg-6">
            <p>{t("home:about_1")}</p>
            <p>{t("home:about_2")}</p>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0">
            <p>{t("home:about_3")}</p>
            <p>{t("home:about_4")}</p>

            {/* <a href="/#" className="btn-learn-more">
              Learn More
            </a> */}
          </div>
        </div>
        {/* <div className="row content">
          <div className="col-lg-12">
            <ul>
              <li>
                <i className="ri-check-double-line"></i> Ullamco laboris nisi ut
                aliquip ex ea commodo consequat
              </li>
              <li>
                <i className="ri-check-double-line"></i> Duis aute irure dolor
                in reprehenderit in voluptate velit
              </li>
              <li>
                <i className="ri-check-double-line"></i> Ullamco laboris nisi ut
                aliquip ex ea commodo consequat
              </li>
            </ul>
          </div>
        </div> */}
      </div>
    </section>
    // {/* <!-- End About Us Section --> */}
  );
}
