export default {
  lang: "Francais",
  title: "SOPROMI",
  home: "Accueil",
  about: "A propos",
  services: "Services",
  portofilio: "Galerie",
  team: "Equipe",
  contact: "Contactez nous",
  introduction: "Le meilleur partenaire pour votre projet",
  introduction2:
    "Nous sommes une équipe d'ingénieurs talentueux et expérimentés",
  aboutUs: "A propos",
  about_1: `Nous sommes la compagnie SOPROMI basée en Guinée dans le secteur de l’Immobilier,
  de la Construction, des Travaux publics, du Génie Civil et des opérations minières.
  Nous sommes une équipe éthique et tournée vers l’avenir qui continue de créer et
  produire les résultats prévisibles en matière d’ingénierie sure et de haute qualité.`,
  about_2: `Nous avons bâti la réputation pour notre société grâce à la qualification, au
  professionnalisme et à l’engagement de notre personnel.
  Étant une entreprise appartenant à ses employés, les actifs de SOPROMI sont gérés par
  les personnes qui connaissent le mieux notre travail.`,
  about_3: `Quelque soit les besoins de nos clients, nous nous efforçons en permanence à les
  proposer les solutions techniques les mieux adaptées et à réaliser des projets de haute
  qualité au meilleur coût.
  Nous cherchons continuellement à intégrer les concepts du développement durable et
  de la protection de protection de l’environnement dans le cycle de vie de tous nos
  projets.`,
  about_4: `Nous couvrons tous les aspects des projets, des plans jusqu’à la réalisation.
  La vision de notre société est axée sur l’innovation, le développement durable,
l’amélioration continue et l’engagement dans nos différents secteurs d’activité.`,
  activities: "Secteurs d'activités",
  serviceOneTitle: "Construction et Promotion immobilière",
  serviceOneContent: `Depuis sa création, SOPROMI a œuvré dans le secteur de la construction et de
  l’immobilier. Ainsi, nous effectuons la promotion immobilière, la construction, la
  rénovation et la gestion des bâtiments institutionnels, résidentiels et commerciaux.
  Notre réputation a été établie par notre capacité à offrir à nos partenaires des projets
  de haute qualité à moindre coût et au respect des échéanciers.`,
  serviceTwoTitle: "Travaux publics et Génie Civil",
  serviceTwoContent: `Grace à notre personnel qualifié et notre flotte d’équipement, nous avons réalisé des
  travaux diverse et complexes notamment dans la construction et l’entretient des
  ouvrages (piste, routes, ponts et autres ouvrages de franchissement, bassin de retenue,
  égouts, aqueduc, etc.). La société SOPROMI s’est doté d’une équipe professionnelle,
  expérimentée et qualifiée en matière de Génie Civil.`,
  serviceThreeTitle: "Opération minière",
  serviceThreeContent: "A remplir ...",
  serviceFourTitle: "Location d’équipement et de matériels de construction",
  serviceFourContent: `Pour vos travaux de construction, de génie civil, la compagnie SOPROMI vous offres des
  services de location des matériels de construction et de la machinerie lourdes de tous
  genre à Conakry et dans toutes les autres villes de Guinée.
  La fiabilité et la performance de nos matériels et équipements sont facteurs mis en
  œuvre pour assurer un environnement de travail sécuritaires pour nos clients et nos
  employés.`,
  serviceFiveTitle: "To write ...",
  serviceFiveContent: "To write ...",
  serviceSixTitle: "To write",
  serviceSixContent: "To write",
  career: "Carrières",
  careerIntro:
    "Perspective de carrière : à Conakry et sur nos différents chantiers (Conakry, Guinée)",
  availablePositions: "Postes disponibles",
  projectEngineer: "Ingénieur de projet",
  spontaniousCandidate: "Candidature Spontanée",
  equipments: "Équipements",
  equipmentsIntro:
    "Ci dessous une liste d'équipements que nous proposons a louer",
  heavyEquipments: "Equipements lourds",
  pelleMecanique: "Pelle Mecanique (Excavatrice)",
  marteauHydraulique: "Marteau Hydraulique (marteau piqueur)",
  buldozer: "Buldozer",
  chargeurSurRoue: "Chargeur sur roue (Loader)",
  niveleuse: "Niveleuse (Gradeur)",
  rouleauCompacteur: "Rouleau compacteur",
  betonniere: "Bétonnière",
  camionBenne: "Camion Benne (10 et 12 roues)",
  camionSemiRemorque: "Camion Semi Remorque",
  grue: "Grue",
  camionPlateau: "Camion plateau",
  porteChar: "Porte char",
  citerneAEau: "Citerne à eau",
  citerneACarburant: "Citerne à carburant (Fuel, Gasoil, Essence)",
  constructionMaterial: "Matériels de Construction",
  echafaudages: "Échafaudages",
  etaisMetaliques: "Étais métalliques",
  coffragesMetalliques: "Coffrages métaliques et accessoires",
  betonniereEtMalaxeur: "Bétonnière et malaxeur",
  aiguilleVibrante: "Aiguille vibrante (Vibrateur à béton)",
  priseDeBetonHydrolique: "Prise de beton hydraulique",
  machineAProjeterAvecMalaxeurFixe: "Machine à projeter avec malaxeur fixe",
  ventilateurSecheur: "Ventilateur sécheur",
  plaqueVibrante: "Plaque vibrante",
  pilonneuse: "Pilonneuse",
  marteauPneumatique: "Marteau Pneumatique",
  briseRocheHydraulique: "Brise roche hydraulique",
  marteauPerforateur: "Marteau perforateur",
  decoupeuse: "Découpeuse",
  decoupeuseDeFerABeton: "Découpeuse de fer à beton",
  boulonneuse: "Boulonneuse",
  pompeAEau: "Pompe à eau",
  materielDeLavage: "Materiel de lavage",
  posteASouder: "Poste a souder",
  systemeEclairage: "Système d'éclairage",
  contactIntro: `Pour communiquer avec SOPROMI, veillez remplir ce formulaire et spécifier votre
  demande.`,
  thankYouForTheInterest: `Nous vous remercions de votre intérêt pour SOPROMI`,
  requireFieldIndication: "(*) Indique un champ obligatoire",
  address: "Adresse:",
  ourEmail: "Email:",
  ourPhoneNumber: "Numero de téléphone",
  ourPhone: "Téléphone",
  name: "Votre nom",
  email: "Votre email",
  phone: "Votre numero de téléphone",
  subject: "Sujet",
  message: "Votre message",
  sendMessage: "Envoyer",
  loading: "Chargement",
  iWantToRent: "Ajouter des équipements ou materiels à jouer",
  selectEquipment: "Selectionner l'équipement que vous voulez louer",
  messageSent: "Votre message a été envoyé. Merci!",
  ourSocialNetWork: "Nos Réseaux sociaux",
  socialNetworkIntro: "Vous pouvez nous joindres sur les réseaux sociaux",
  allRightsReserved: "Tous droits reservés",
  designedBy: "Fait par",
  done: "Terminer",
  emailError: "Veuillez entrer un email valide",
  nameError: "Veuillez entrer un nom valid",
  phoneError: "Veuillez entrer un numéro de téléphone valide",
  addedEquipment: "équipement(s) a louer",
  sending: "Envoi ...",
  notFound: "Page non trouvée",
  goHome: "Retourner à la page d'accueil",
  french: "Français",
  english: "Anglais",
};
