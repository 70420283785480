import React from "react";
import { useTranslation } from "react-i18next";
// import i18n from "../../../locales";

export default function Header() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <header id="header" className="fixed-top ">
      <div className="container d-flex align-items-center">
        {/* <h1 className="logo mr-auto">
          <a href="/">{t("home:title")}</a>
        </h1> */}
        {/* <!-- Uncomment below if you prefer to use an image logo --> */}
        <a href="/" className="logo mr-auto">
          <img src="assets/img/logo-sopromi.png" alt="" className="img-fluid" />
        </a>

        <nav className="nav-menu d-none d-lg-block">
          <ul>
            <li className="active">
              <a href="/">{t("home:home")}</a>
            </li>
            <li>
              <a href="#about">{t("home:about")}</a>
            </li>
            <li>
              <a href="#activities">{t("home:activities")}</a>
            </li>
            <li>
              <a href="#skills">{t("home:career")}</a>
            </li>
            {/* <li>
              <a href="#equipments">{t("home:equipments")}</a>
            </li> */}
            {/* <li>
              <a href="#portfolio">{t("home:portofilio")}</a>
            </li> */}
            {/* <li>
              <a href="#team">{t("home:team")}</a>
            </li> */}
            {/* <li className="drop-down">
              <a href="/#">Drop Down</a>
              <ul>
                <li>
                  <a href="/#">Drop Down 1</a>
                </li>
                <li className="drop-down">
                  <a href="/#">Deep Drop Down</a>
                  <ul>
                    <li>
                      <a href="/#">Deep Drop Down 1</a>
                    </li>
                    <li>
                      <a href="/#">Deep Drop Down 2</a>
                    </li>
                    <li>
                      <a href="/#">Deep Drop Down 3</a>
                    </li>
                    <li>
                      <a href="/#">Deep Drop Down 4</a>
                    </li>
                    <li>
                      <a href="/#">Deep Drop Down 5</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="/#">Drop Down 2</a>
                </li>
                <li>
                  <a href="/#">Drop Down 3</a>
                </li>
                <li>
                  <a href="/#">Drop Down 4</a>
                </li>
              </ul>
            </li> */}
            <li>
              <a href="#contact">{t("home:contact")}</a>
            </li>
            {/* <li>
              <a
                href="#language"
                onClick={(e) => {
                  console.log({ baila: false });
                  e.preventDefault();
                  e.stopPropagation();
                  changeLanguage(
                    i18n.language.includes("fr") ? "en-En" : "fr-Fr"
                  );
                  return false;
                }}
              >
                {i18n.language.includes("fr")
                  ? t("home:english")
                  : t("home:french")}
              </a>
            </li> */}
            {/* {i18n.language.includes("fr") ? (
              <li>
                <a
                  href="#language"
                  onClick={(e) => {
                    e.preventDefault();
                    changeLanguage("en-En");
                    return false;
                  }}
                >
                  {t("home:english")}
                </a>
              </li>
            ) : (
              <li>
                <a
                  href="#language"
                  onClick={(e) => {
                    e.preventDefault();
                    changeLanguage("fr-Fr");
                    return false;
                  }}
                >
                  {t("home:french")}
                </a>
              </li>
            )} */}
          </ul>
        </nav>
        {/* <!-- .nav-menu --> */}

        {/* <a href="#about" className="get-started-btn scrollto">
          Get Started
        </a> */}
      </div>
    </header>
  );
}
