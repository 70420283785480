import React, { useMemo, useState, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import Select from "react-select";

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function emailIsValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

const emptyForm = {
  name: "",
  email: "",
  phone: "",
  subject: "",
  message: "",
  toRentEquipments: "",
  toRentMaterials: "",
};

export default function Contact() {
  const { t } = useTranslation();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const [selectedMaterials, setSelectedMaterials] = useState([]);

  const [menuIsOpen, setMenuIsOpen] = useState(true);

  const [contactForm, setContactForm] = useState(emptyForm);

  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const sendMessage = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError({});
    // name validation
    if (!contactForm.name.length || contactForm.name.length < 3) {
      setError({ ...error, name: t("home:nameError") });
      setIsLoading(false);
      return;
    }

    // email validation
    if (contactForm.email.length && !emailIsValid(contactForm.email)) {
      setError({ ...error, email: t("home:emailError") });
      setIsLoading(false);
      return;
    }

    if (!contactForm.phone.length) {
      setError({ ...error, phone: t("home:phoneError") });
      setIsLoading(false);
      return;
    }

    const {
      REACT_APP_EMAILJS_RECEIVER: receiverEmail,
      REACT_APP_EMAILJS_TEMPLATEID: template,
      REACT_APP_EMAILJS_USERID: user,
    } = process.env;

    console.log({ contactForm, receiverEmail });

    sendEmail(
      template,
      "bailakahn@yahoo.fr",
      receiverEmail,
      "Baila Test",
      user
    );
  };

  const sendEmail = (
    templateId,
    senderEmail,
    receiverEmail,
    feedback,
    user
  ) => {
    window.emailjs
      .send(
        "default_service", // default email provider in your EmailJS account
        templateId,
        {
          senderEmail,
          receiverEmail,
          ...contactForm,
        },
        user
      )
      .then((res) => {
        setShowSuccess(true);
        setTimeout(() => {
          setShowSuccess(false);
        }, 2000);
        console.log({ res });
        setContactForm(emptyForm);
        setIsLoading(false);
      })
      // Handle errors here however you like, or use a React error boundary
      .catch((err) => console.error("Failed to send feedback. Error: ", err));
  };
  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth < 770) {
        setMenuIsOpen(false);
      } else {
        setMenuIsOpen(true);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const updateContactForm = (name, value) => {
    setContactForm({ ...contactForm, [name]: value });
  };

  const heavyEquipmentList = useMemo(
    () => ({
      label: t("home:heavyEquipments"),
      items: [
        { label: t("home:pelleMecanique"), value: "pelleMecanique" },
        { label: t("home:marteauHydraulique"), value: "marteauHydraulique" },
        { label: t("home:buldozer"), value: "buldozer" },
        { label: t("home:chargeurSurRoue"), value: "chargeurSurRoue" },
        { label: t("home:niveleuse"), value: "niveleuse" },
        { label: t("home:rouleauCompacteur"), value: "rouleauCompacteur" },
        { label: t("home:betonniere"), value: "betonniere" },
        { label: t("home:camionBenne"), value: "camionBenne" },
        { label: t("home:camionSemiRemorque"), value: "camionSemiRemorque" },
        { label: t("home:grue"), value: "grue" },
        { label: t("home:camionPlateau"), value: "camionPlateau" },
        { label: t("home:porteChar"), value: "porteChar" },
        { label: t("home:citerneAEau"), value: "citerneAEau" },
        { label: t("home:citerneACarburant"), value: "citerneACarburant" },
      ],
    }),
    []
  );

  const constructionMaterialList = useMemo(
    () => ({
      label: t("home:constructionMaterial"),
      items: [
        { label: t("home:echafaudages"), value: "echafaudages" },
        { label: t("home:etaisMetaliques"), value: "etaisMetaliques" },
        {
          label: t("home:coffragesMetalliques"),
          value: "coffragesMetalliques",
        },
        {
          label: t("home:betonniereEtMalaxeur"),
          value: "betonniereEtMalaxeur",
        },
        { label: t("home:aiguilleVibrante"), value: "aiguilleVibrante" },
        {
          label: t("home:priseDeBetonHydrolique"),
          value: "priseDeBetonHydrolique",
        },
        {
          label: t("home:machineAProjeterAvecMalaxeurFixe"),
          value: "machineAProjeterAvecMalaxeurFixe",
        },
        { label: t("home:ventilateurSecheur"), value: "ventilateurSecheur" },
        { label: t("home:plaqueVibrante"), value: "plaqueVibrante" },
        { label: t("home:pilonneuse"), value: "pilonneuse" },
        { label: t("home:marteauPneumatique"), value: "marteauPneumatique" },
        {
          label: t("home:briseRocheHydraulique"),
          value: "briseRocheHydraulique",
        },
        { label: t("home:marteauPerforateur"), value: "marteauPerforateur" },
        { label: t("home:decoupeuse"), value: "decoupeuse" },
        {
          label: t("home:decoupeuseDeFerABeton"),
          value: "decoupeuseDeFerABeton",
        },
        { label: t("home:boulonneuse"), value: "boulonneuse" },
        { label: t("home:pompeAEau"), value: "pompeAEau" },
        { label: t("home:materielDeLavage"), value: "materielDeLavage" },
        { label: t("home:posteASouder"), value: "posteASouder" },
        { label: t("home:systemeEclairage"), value: "systemeEclairage" },
      ],
    }),
    []
  );
  return (
    // {/* <!-- ======= Contact Section ======= --> */}
    <section id="contact" className="contact">
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            height: "50%",
          },
        }}
        contentLabel="Example Modal"
      >
        <div className="form-row">
          <div className="form-group col-md-6">
            <Select
              defaultValue={selectedEquipments}
              menuIsOpen={menuIsOpen}
              closeMenuOnSelect={false}
              isMulti
              onChange={(options) => {
                if (options) setSelectedEquipments(options);
                else setSelectedEquipments([]);
              }}
              options={heavyEquipmentList.items}
            />
          </div>
          <div className="form-group col-md-6">
            <Select
              defaultValue={selectedMaterials}
              menuIsOpen={menuIsOpen}
              closeMenuOnSelect={false}
              isMulti
              onChange={(options) => {
                if (options) setSelectedMaterials(options);
                else setSelectedMaterials([]);
              }}
              options={constructionMaterialList.items}
            />
          </div>
        </div>
        <div
          className="row"
          style={{ position: "absolute", bottom: 10, right: 30 }}
        >
          <button
            className="btn btn-primary"
            onClick={() => {
              var toRentEquipments = ``;
              var toRentMaterials = ``;

              if (selectedEquipments.length) {
                toRentEquipments = `
                <h3>Equipements lourds a louer</h3>
                <ul>
                `;

                selectedEquipments.forEach(({ label }) => {
                  toRentEquipments += `<li>${label}</li>`;
                });

                toRentEquipments += `</ul>`;
              }

              if (selectedMaterials.length) {
                toRentMaterials = `
                <h3>Materiels de construction a louer</h3>
                <ul>
                `;

                selectedMaterials.forEach(({ label }) => {
                  toRentMaterials += `<li>${label}</li>`;
                });

                toRentMaterials += `</ul>`;
              }

              setContactForm({
                ...contactForm,
                toRentEquipments,
                toRentMaterials,
              });

              setModalIsOpen(false);
            }}
          >
            {t("home:done")}
          </button>
        </div>
      </Modal>
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>{t("home:contact")}</h2>
          <p>{t("home:contactIntro")}</p>
          <p>{t("home:thankYouForTheInterest")}</p>
        </div>

        <div className="row">
          <div className="col-lg-5 d-flex align-items-stretch">
            <div className="info">
              <div className="address">
                <i className="icofont-google-map"></i>
                <h4>{t("home:address")}</h4>
                <p>
                  Cité chemin de Fer, immeuble Boké, <br />
                  4 ème étage, C/ Kaloum, Conakry,
                  <br />
                  Guinée <br />
                  <br />
                </p>
              </div>

              <div className="email">
                <i className="icofont-envelope"></i>
                <h4>{t("home:ourEmail")}</h4>
                <p>sopromisarlu@gmail.com</p>
              </div>

              <div className="phone">
                <i className="icofont-phone"></i>
                <h4>{t("home:ourPhoneNumber")}</h4>
                <p>+224 666 84 42 84</p>
              </div>

              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1967.4676262994867!2d-13.707891449733555!3d9.51435513803896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xf1cd09517044b4f%3A0xe3d7acd0d5ef3ebe!2sCit%C3%A9%20Chemin%20de%20Fer%2C%20Conakry%2C%20Guin%C3%A9e!5e0!3m2!1sfr!2sca!4v1598831441094!5m2!1sfr!2sca"
                frameBorder="0"
                style={{ border: 0, width: "100%", height: 290 }}
                allowFullScreen
                aria-hidden="false"
                tabIndex="0"
                title="address"
              ></iframe>
            </div>
          </div>

          <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch ">
            <form className="php-email-form">
              <span style={{ color: "#1E25B4" }}>
                <br />
                {t("home:requireFieldIndication")}
              </span>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="name">{t("home:name")}</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 4 chars"
                    value={contactForm.name}
                    onChange={(e) => updateContactForm("name", e.target.value)}
                  />
                  {error.name && (
                    <div className="validate" style={{ display: "block" }}>
                      {error.name}
                    </div>
                  )}
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="name">{t("home:email")}</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    data-rule="email"
                    data-msg="Please enter a valid email"
                    value={contactForm.email}
                    onChange={(e) => updateContactForm("email", e.target.value)}
                  />
                  {error.email && (
                    <div className="validate" style={{ display: "block" }}>
                      {error.email}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="phone">{t("home:phone")}</label>
                  <input
                    type="text"
                    name="phone"
                    className="form-control"
                    id="phone"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 4 chars"
                    value={contactForm.phone}
                    onChange={(e) => updateContactForm("phone", e.target.value)}
                  />
                  {error.phone && (
                    <div className="validate" style={{ display: "block" }}>
                      {error.phone}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-row">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setModalIsOpen(true);
                  }}
                  className="btn modalButton"
                >
                  {t("home:iWantToRent")}
                </button>
              </div>

              <br />
              {(!!selectedMaterials.length || !!selectedEquipments.length) && (
                <div>
                  <h5>{`${
                    selectedEquipments.length + selectedMaterials.length
                  } ${t("home:addedEquipment")}`}</h5>
                </div>
              )}
              <div className="form-group">
                <label htmlFor="subject">{t("home:subject")}</label>
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  id="subject"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 8 chars of subject"
                  value={contactForm.subject}
                  onChange={(e) => updateContactForm("subject", e.target.value)}
                />
                <div className="validate"></div>
              </div>
              <div className="form-group">
                <label htmlFor="name">{t("home:message")}</label>
                <textarea
                  className="form-control"
                  name="message"
                  rows="5"
                  data-rule="required"
                  data-msg="Please write something for us"
                  value={contactForm.message}
                  onChange={(e) => updateContactForm("message", e.target.value)}
                ></textarea>
                <div className="validate"></div>
              </div>
              <div className="mb-3">
                <div className="loading">{t("home:loading")}</div>
                <div className="error-message"></div>
                {showSuccess && (
                  <div className="sent-message">{t("home:messageSent")}</div>
                )}
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  onClick={sendMessage}
                  disabled={isLoading}
                >
                  {isLoading ? t("home:sending") : t("home:sendMessage")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    // {/* <!-- End Contact Section --> */}
  );
}
