import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import frenchTranslations from "./fr";
import englishTranslations from "./en";

console.log({ frenchTranslations });
i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: englishTranslations,

    fre: frenchTranslations,
  },
  fallbackLng: "fre",
  debug: true,

  // have a common namespace used around the full app
  ns: Object.keys(frenchTranslations),
  defaultNS: "home",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
  },

  react: {
    wait: true,
  },
});

export default i18n;
