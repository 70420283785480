import React from "react";

// import Newsletter from "./Newsletter";
import FooterTop from "./FooterTop";
import CopyRight from "./CopyRight";

export default function Footer() {
  return (
    <footer id="footer">
      {/* <Newsletter /> */}
      <FooterTop />
      <CopyRight />
    </footer>
  );
}
