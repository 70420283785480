import React from "react";
import { useTranslation } from "react-i18next";

export default function WhyUs() {
  const { t } = useTranslation();

  return (
    // {/* <!-- ======= Why Us Section ======= --> */}

    <section id="activities" className="why-us section-bg">
      <div className="container-fluid" data-aos="fade-up">
        <div className="row">
          <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
            <div className="content">
              <div className="section-title">
                <h2>{t("home:activities")}</h2>
              </div>
            </div>

            <div className="accordion-list">
              <ul>
                <li>
                  <a
                    data-toggle="collapse"
                    className="collapse"
                    href="#accordion-list-1"
                  >
                    <span>01</span> <span>{t("home:serviceOneTitle")}</span>{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="accordion-list-1"
                    className="collapse show"
                    data-parent=".accordion-list"
                  >
                    <p>{t("home:serviceOneContent")}</p>
                  </div>
                </li>

                <li>
                  <a
                    data-toggle="collapse"
                    href="#accordion-list-2"
                    className="collapsed"
                  >
                    <span>02</span> <span>{t("home:serviceTwoTitle")}</span>
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="accordion-list-2"
                    className="collapse"
                    data-parent=".accordion-list"
                  >
                    <p>{t("home:serviceTwoContent")}</p>
                  </div>
                </li>

                <li>
                  <a
                    data-toggle="collapse"
                    href="#accordion-list-3"
                    className="collapsed"
                  >
                    <span>03</span> <span>{t("home:serviceThreeTitle")}</span>
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="accordion-list-3"
                    className="collapse"
                    data-parent=".accordion-list"
                  >
                    <p>{t("home:serviceThreeContent")}</p>
                  </div>
                </li>

                <li>
                  <a
                    data-toggle="collapse"
                    href="#accordion-list-3"
                    className="collapsed"
                  >
                    <span>04</span> <span>{t("home:serviceFourTitle")}</span>
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="accordion-list-3"
                    className="collapse"
                    data-parent=".accordion-list"
                  >
                    <p>{t("home:serviceFourContent")}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div
            className="col-lg-5 align-items-stretch order-1 order-lg-2 img"
            style={{
              backgroundImage: `url("assets/img/service-5.png") `,
            }}
            data-aos="zoom-in"
            data-aos-delay="150"
          >
            &nbsp;
          </div>
        </div>
      </div>
    </section>
    // {/* <!-- End Why Us Section --> */}
  );
}
